import { Application } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"
import { registerControllers } from "stimulus-vite-helpers"

// import "chartkick/chart.js"
// import { Chart } from "chart.js"
// import annotationPlugin from "chartjs-plugin-annotation"
// Chart.register(annotationPlugin)

const application = Application.start()
const controllers = import.meta.glob("../**/*_controller.ts", { eager: true })
registerControllers(application, controllers)
